import React from "react";

const Welcomescreen = () => {
  return (
    <div className="musiclogin_main">
      <div className="mausiclogin_main_inner">
        <img className="" src="./img/twiineblack.png" />
      </div>
    </div>
  );
};

export default Welcomescreen;
