import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Geocode from "react-geocode";
import { getDistance } from "geolib";
import CircularIndeterminate from "../spotify/Loader";
import useGeolocation from "react-hook-geolocation";
import { toPng } from "html-to-image";
import { useCallback } from "react";
import { baseUrl } from "../../Services/Config";
import ReplayIcon from "@mui/icons-material/Replay";
import axios from "axios";
import axiosApiInstance from "../../Services/spotifyService";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { convertNeSwToNwSe } from "google-map-react";
import vibesdata from "../../Json/vibes.json";
import Appleinsta from "./Appleinsta";
import category from "../../Json/Category.json";

// const baseUrl = "http://localhost:8000/";
const Applemuicyoulike = (props) => {
  const [filter, setFilter] = useState(null);
  const [indexset, setIndexset] = useState();
  const [active, setActive] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [findvibesPara, setfindvibesPara] = useState([]);
  const [classs, setClasss] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });
  const [yesno, setYesno] = useState(false);
  const [pricevalue, setPricevalue] = useState();
  const [showbtn, setShowbtn] = useState(false);

  const [filterdata, setFilterData] = useState({ status: false, data: [] });

  const [userGeners, setUsergeners] = useState({ status: false, data: [] });
  const [foodcat, setFoodCat] = useState(false);
  const [vibes, setVibes] = useState(false);
  const [location, setLocation] = useState({
    status: false,
    latitude: "",
    longitude: "",
  });
  const marks = [
    {
      value: 1,
      label: "1 Mile",
    },
    {
      value: 50,
      label: "50 Miles",
    },
  ];
  function valuetext(value) {
    setFilter(value);
    // localStorage.setItem("selectedMile", value);
  }
  const [showItem, setShowItem] = useState(3);
  const [startItem, setStartItem] = useState(0);
  const refs = document.getElementById("id");
  const [story, setStory] = useState(false);
  const geolocation = useGeolocation();
  const [model, setModel] = useState(false);

  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let datamile = localStorage.getItem("selectedMile");
  useEffect(() => {
    if (!userGeners.status) {
      getGenerslist();
    }
  }, []);

  useEffect(() => {
    if (userGeners.status && !location.status) {
      if (geolocation?.latitude && geolocation?.longitude) {
        setLocation({
          status: true,
          latitude: geolocation.latitude,
          longitude: geolocation.longitude,
        });

        getDataByLocation(geolocation?.latitude, geolocation?.longitude);
      } else {
        setLocation({ status: true, latitude: "", longitude: "" });
        getgneredata();
      }
    }
  }, [userGeners]);

  /*Go to Resultbreakdown page */
  const getGeners = () => {
    let path = "/Appleresult";

    navigate(path);
  };
  const existCategoryOne = (categoryArray, categoryName) => {
    if (categoryArray.includes(categoryName)) {
      return categoryName;
    }
  };
  const getDistanceFromCurrent = (cordinates) => {
    let dis = getDistance(
      {
        latitude: location.latitude,
        longitude: location.longitude,
      },
      { latitude: cordinates[1], longitude: cordinates[0] }
    );
    dis = (parseFloat(dis) / 1000 / 1.609).toFixed(1);

    return parseFloat(dis).toFixed(1);
  };

  /* get data by location */
  const getDataByLocation = async (latitude, longitude, price) => {
    const data = await axios
      .get(
        `${baseUrl}filterResturants?lat=${latitude}&long=${longitude}`,

        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        const dupdata = res.data.data;

        let dataArraydistance = dupdata.map((obj) => ({
          ...obj,
          distance: getDistanceFromCurrent(obj.location.coordinates),
        }));

        let filterDataa = [];
        let fil = [];

        if (filter != null) datamile = filter;

        if (datamile) {
          filterDataa = dataArraydistance.filter((ele) => {
            const filterArray = ele.distance <= datamile;
            return filterArray;
          });
        }
        if (filterDataa.length === 0) {
          filterDataa = dataArraydistance;
        }

        if (price != undefined) {
          filterDataa = filterDataa.filter((x) => x.price === price);
        }
        if (catArray.length > 0) {
          filterDataa = dataArraydistance.filter(
            (x) =>
              x.category1 === existCategoryOne(catArray, x.category1) ||
              x.category2 === existCategoryOne(catArray, x.category2) ||
              x.category3 === existCategoryOne(catArray, x.category3)
          );
        }
        if (filterDataa.length) {
          fil = filterDataa.filter(
            (x) =>
              userGeners.data
                .slice(0, 5)
                .includes(x.MusicVibe2?.toLowerCase()) ||
              userGeners.data.slice(0, 5).includes(x.MusicVibe3?.toLowerCase())
          );
        } else {
          filterDataa = dataArraydistance.filter(
            (x) =>
              userGeners.data
                .slice(0, 5)
                .includes(x.MusicVibe2?.toLowerCase()) ||
              userGeners.data.slice(0, 5).includes(x.MusicVibe3?.toLowerCase())
          );
        }

        if (filterDataa.length) {
          if (filterDataa?.length <= 3) {
setShowItem(3)
setStartItem(0)
} 

          return setFilterData({ status: true, data: filterDataa });
        } else if (fil.length) {
          if (filterDataa?.length <= 3) {
            setShowItem(3)
            setStartItem(0)
            } 
          return setFilterData({ status: true, data: fil });
        } else {
          if (filterDataa?.length <= 3) {
            setShowItem(3)
            setStartItem(0)
            }
          return setFilterData({ status: true, data: dataArraydistance });
        }
      });
  };

  const getgneredata = () => {
    const data = axios
      .get(`${baseUrl}withoutfilter`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then((res) => {
        const dupdata = res.data;
        /* condition for checking the user genre with Database genre */
        const data = dupdata.filter(
          (x) =>
            userGeners.data.slice(0, 5).includes(x.MusicVibe2?.toLowerCase()) ||
            userGeners.data.slice(0, 5).includes(x.MusicVibe3?.toLowerCase())
        );

        if (data?.length) {
          return setFilterData({ status: true, data });
        }
        return setFilterData({ status: true, data: dupdata });
      });
  };

  let appletoken = localStorage.getItem("music-user_token");


  const getGenerslist = async (e) => {
    axios
      .get("https://api.music.apple.com/v1/me/recent/played/tracks", {
        headers: {
          "Access-Control-Allow-Origin": "https://twine-new.vercel.app/",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          Authorization:
            "Bearer eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IllIS0xLSk5ZRDMifQ.eyJpYXQiOjE2NjUxNjg0MTIsImV4cCI6MTY4MDcyMDQxMiwiaXNzIjoiTllMVDdCVzg3UiJ9.qM3UV0c7KZiEXMVGkEWXgkEiEcP52WiMz_z71zMD5vnX6V1zOnZJl0jN9VH_4niJnzbYV_s9MhvWwmkC0h29bw",
          "Music-User-Token": `${appletoken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        let array = [];
        response.data.data.forEach((element) => {
          array.push(element.attributes.albumName);
        });

        let genernamesapple = [];
        response.data.data.forEach((element) => {
          genernamesapple.push(element.attributes.genreNames);
        });
        var newArr = [];
        for (var i = 0; i < genernamesapple.length; i++) {
          newArr = newArr.concat(genernamesapple[i]);
        }
        let counts = {};

        for (let i = 0; i < newArr.length; i++) {
          if (counts[newArr[i]]) {
            counts[newArr[i]] += 1;
          } else {
            counts[newArr[i]] = 1;
          }
        }
      var  keysSorted = Object.keys(counts).sort(function(a,b){return counts[b]-counts[a]})
      setUsergeners({data: keysSorted,  status: true,})
      })
      .catch((err) => {
        console.log("eroor", err);
      });
  };

  /* Click function for (share on social media ) button.first run this function then it checks the story state wheather it's true or false, if state is true then onButtonClick function run.*/
  const getStories = () => {
    setStory(true);
    setTimeout(() => {
      onButtonClick();
    }, 3000);
  };

  const handlecategory = (ele) => {
    if (catArray.length === 0) {
      setCatArray([ele]);
    } else {
      if (catArray.includes(ele)) {
        const filteredCatArray = catArray.filter((item) => item !== ele);
        setCatArray(filteredCatArray);
      } else {
        setCatArray((prev) => [...prev, ele]);
      }
    }
  };
  /* Function for downloading image on click (Share on Social media) Button */
  const onButtonClick = useCallback(() => {
    if (refs === null) {
      return;
    }
    toPng(refs)
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "name.png";
        link.href = dataUrl;
        link.click();
        setStory(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [refs]);
  const Singleresturant = async (id) => {
    let val = await filterdata.data.find((ele) => ele._id == id);
    localStorage.setItem("singleRes", JSON.stringify(val));
    localStorage.setItem("location", JSON.stringify(val.location));
    navigate("/resturants");
  };

  const getdatabyscreenfilter = () => {
    if (filter != null || pricevalue != undefined || catArray.length > 0) {
      getDataByLocation(
        geolocation?.latitude,
        geolocation?.longitude,
        pricevalue
      );
      console.log(filterdata?.data.length, " FILTER DATA");

      setModel(false);
      setShowbtn(false);
      setShowItem(showItem + 3);
      setStartItem(startItem + 3);
    }
    setShowbtn(false);
    setModel(false);
    setShowItem(showItem + 3);
    setStartItem(startItem + 3);
  };

  const vibesHandle = async (x, index) => {
    const finddef = await vibesdata.find(
      (ele, index) => ele.name.toLowerCase() === x.toLowerCase()
    );
    setIndexset(index);

    setfindvibesPara(finddef);
    setVibes(true);
  };

  return (
    <div className={story == true ? "download-image" : ""}>
      <div className="Musicyoulike">
        <img className="twiinevblack_logo" src="./img/twiineblack.png" />
        <div className="heading-mobile">
          <h1 className="h1">
            Check out these <span>Food Spots</span>
          </h1>
          <span className="bottom-line">
            {" "}
            We’ve generated some options for you. Check out these food spots.
          </span>{" "}
        </div>
        <div className="heading">
          Based on your
          <span
            style={{
              color: "#4E6132",
              marginLeft: "15px",
              position: "relative",
            }}
          >
            music taste
            <img className="Location_Vector_logo" src="./img/Vector.png" />
          </span>
          , we've generated some options for you! Check out these food spots.
        </div>
      </div>

      {/* {localDatafilter? ( */}
      <>
        {filterdata.data.length ? (
          <div className="row cards Musicyoulikes">
            {filterdata.data?.slice(startItem, showItem).map((ele, key) => (
              <div className="col-12 col-md-4" key={key}>
                <div className="Musicyoulike_card_blue">
                  <div className="pro-img">
                    <img
                      onClick={() => {
                        Singleresturant(ele._id);
                      }}
                      className="img"
                      src={ele?.image1 ? ele?.image1 : ele?.image2}
                    />
                  </div>
                  <div className="card_content">
                    <p
                      onClick={() => {
                        Singleresturant(ele._id);
                      }}
                      style={{ paddingTop: "1rem" }}
                      className="businnes"
                    >
                      {ele?.businessName}{" "}
                      <span className="mobile-hide">{ele?.price}</span>
                    </p>
                    {!location.latitude ? (
                      ""
                    ) : (
                      <p>
                        <span className="mobile-hide"> Distance:</span>
                        &nbsp;
                        <span className="dist">
                          {getDistanceFromCurrent(ele?.location?.coordinates)}{" "}
                          miles
                        </span>
                      </p>
                    )}
                    <p className="location_mobile_hide">
                      <span className="mobile-hide"> Location :</span>{" "}
                      <i
                        className="fa fa-map-marker mobile-show "
                        aria-hidden="true"
                      ></i>
                      {ele?.city}{" "}
                      <span className="mobile-show"> • {ele?.price}</span>
                    </p>

                    <p>
                      <span className="mobile-hide">Vibes :</span>
                      &nbsp;{" "}
                      <span
                        className="gener-name"
                        onClick={() => vibesHandle(ele?.MusicVibe3, key)}
                      >
                        {" "}
                        {ele?.MusicVibe3 ? ele?.MusicVibe3 : "Jazz"}{" "}
                      </span>{" "}
                      &nbsp;
                      <span
                        className="gener-name"
                        onClick={() => vibesHandle(ele?.MusicVibe2, key)}
                      >
                        {ele?.MusicVibe2 ? ele?.MusicVibe2 : "Rock"}
                      </span>
                    </p>
                  </div>

                  <button className="Moreinfo btn" type="button">
                    <a href={ele?.yelpURL} target="_blank">
                      see more info
                      <img
                        className="right-arrow"
                        src="./img/right-arrow.png"
                        alt=""
                      />
                    </a>
                  </button>
                </div>
              </div>
            ))}

            {vibes ? (
              <div
                className={
                  indexset == 0
                    ? "Musicyoulike_modal"
                    : "" || indexset == 1
                    ? "Musicyoulike_modal one"
                    : "" || indexset == 2
                    ? "Musicyoulike_modal two"
                    : ""
                }
              >
                <i
                  className="fa fa-close cross"
                  onClick={() => {
                    setVibes(false);
                  }}
                ></i>

                <h3>What vibes should I expect?</h3>
                <p>
                  {findvibesPara?.defination
                    ? findvibesPara?.defination
                    : "Lorem ipsum dolor sit amet"}
                </p>
              </div>
            ) : (
              ""
            )}

            <div className="show_results_main">
              {model ? (
                <div className={yesno ? "filterby yes" : "filterby no"}>
                  <div className="filterClas">
                    <h3 className="text-center filter">Filter : by</h3>
                    <i
                      className="fa fa-close cross"
                      onClick={() => {
                        setModel(false);
                        setShowbtn(false);
                        setFoodCat(false);
                      }}
                    >
                      X
                    </i>
                  </div>
                  <div className="food">
                    <p>
                      Food Category :{" "}
                      <i
                        className="fa fa-plus"
                        aria-hidden="true"
                        onClick={() => setFoodCat(true)}
                      >
                        +
                      </i>
                    </p>

                    {foodcat ? (
                      <div className="food-cat">
                        <i
                          className="fa fa-close cross"
                          onClick={() => {
                            setFoodCat(false);
                          }}
                        >
                          X
                        </i>
                        <div className="cat">
                          {category.map((ele, index) => (
                            <p
                              className={catArray.includes(ele) ? "active" : ""}
                              key={index}
                              value={ele}
                              onClick={(e) => handlecategory(ele, index)}
                            >
                              {ele}
                            </p>
                          ))}
                        </div>

                        <button
                          className="btn"
                          type="button"
                          onClick={(e) => {
                            getdatabyscreenfilter();
                          }}
                        >
                          Filter
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="disno">
                      <label className="col-4">Distance : </label>
                      <Slider
                        getAriaValueText={valuetext}
                        valueLabelDisplay="on"
                        min={1}
                        max={50}
                      />
                    </div>
                    <p className="price-music">
                      Price :{" "}
                      <span
                        className={classs.first ? "nam-bg" : ""}
                        onClick={() => {
                          setClasss({ first: true });
                          setPricevalue("$");
                        }}
                      >
                        $
                      </span>{" "}
                      &nbsp;
                      <span
                        className={classs.second ? "nam-bg" : ""}
                        onClick={() => {
                          setClasss({ second: true });
                          setPricevalue("$$");
                        }}
                      >
                        $$
                      </span>{" "}
                      <span
                        className={classs.third ? "nam-bg" : ""}
                        onClick={() => {
                          setClasss({ third: true });
                          setPricevalue("$$$");
                        }}
                      >
                        $$$
                      </span>{" "}
                      <span
                        className={classs.fourth ? "nam-bg" : ""}
                        onClick={() => {
                          setClasss({ fourth: true });
                          setPricevalue("$$$$");
                        }}
                      >
                        $$$$
                      </span>
                    </p>
                    <div className="filter_switcher">
                      <p>Show results only open now? </p>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckDefault"
                          onClick={() => {
                            setYesno((prev) => !prev);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="results_three_btn">
                    <button
                      className="btn"
                      type="button"
                      onClick={(e) => {
                        getdatabyscreenfilter();
                      }}
                    >
                      <ReplayIcon /> Show 3 more results
                    </button>
                    {model === true ? (
                      ""
                    ) : (
                      <>
                      <div
                        className="ham-burger"
                        onClick={() => setModel(true)}
                      >
                        <i className="fa fa-bars" />
                        
                      </div>
                      <div 
                      className="desktop-show"
                      onClick={()=>setModel(true)}
                      >
<i className="fa fa-filter" aria-hidden="true"/>
&nbsp; Filter




                      </div>

                      </>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              {filterdata.data.length <= 3 ? (
                ""
              ) : (
                <div className="results_three_btn">
                  {showbtn === false ? (
                    <button
                      className="btn"
                      type="button"
                      onClick={(e) => {
                        getdatabyscreenfilter();
                      }}
                    >
                      <ReplayIcon /> Show 3 more results
                    </button>
                  ) : (
                    ""
                  )}
                  {model === true ? (
                    ""
                  ) : (

                    <>
                    
                    <div
                      className="ham-burger"
                      onClick={() => {
                        setModel(true);
                        setShowbtn(true);
                      }}
                    >
                      <i className="fa fa-bars" />
                    </div>
                    
                    
                    <div 
                      className="desktop-show"
                      onClick={()=>setModel(true)}
                      >
<i className="fa fa-filter" aria-hidden="true"/>
&nbsp; Filter




                      </div>

                    </>
                   
                    
                  )}
                </div>
              )}
            </div>

            <div className="share_buttons">
              <button className="btn" type="button" onClick={getGeners}>
                <img className="genere-image" src="./img/31.png" alt="" />
                See your genre Breakdown
              </button>
              <button
                className="btn mobile-show-btn "
                type="button"
                onClick={getStories}
              >
                <img className="genere-image" src="./img/share.png" />
                Share on social media
              </button>
              <a
                className="btn "
                type="button"
                href="https://forms.gle/gfVL5MjSPxDUTHsw7"
                target="_blank"
              >
                <img className="genere-image" src="./img/rocket.png" />
                Subscribe for product updates
              </a>
            </div>
          </div>
        ) : (
          <>
            <CircularIndeterminate />
          </>
        )}
      </>
      {/* ) */}

      {story == true ? (
        <div className="download ">
          <CircularIndeterminate />
        </div>
      ) : null}
      <Appleinsta rest={props.rest} story={story} filterdata={filterdata} />
    </div>
  );
};

export default Applemuicyoulike;
