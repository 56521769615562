import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import { useNavigate } from "react-router-dom";
import { setLocationType } from "react-geocode";
import { getDistance } from "geolib";

const AppleSingleresturant = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [location, setLocation] = useState();
  const [map, setMap] = useState(false);
  useEffect(() => {
    setData(JSON.parse(localStorage.getItem("singleRes")));
    setLocation(JSON.parse(localStorage.getItem("location")));
  }, []);
  const defaultProps = {
    center: {
      lat: location?.coordinates[1],
      lng: location?.coordinates[0],
    },
    zoom: 16,
  };
  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  const goBack = () => {
    navigate("/likemusic");
  };
  setTimeout(() => {
    setMap(true);
  }, 2000);

  return (
    <div className="Musicyoulike location-single">
      <div className="resturan-Data">
        <div className="twiinevblack_img">
          <img className="twiinevblack_logo" src="./img/twiineblack.png" />
        </div>
        <button className="goback btn" type="button" onClick={() => goBack()}>
          <img className="back" src="./img/back-arrow.png" />
          <span style={{ color: "#FE3C3C", fontWeight: "bold" }}> Go Back</span>
        </button>
        <img className="w-100 singleres_banner" src={data.image1} />
        <div className="resturants">
          <div className="singleres">
            <div className="busin-start">
              <p> {data.businessName}</p>
              <p>
                <i class="fa fa-star" aria-hidden="true" />
                {data.rating}(100)
              </p>
            </div>
            <p style={{ color: "#000" }} className="dist">
              {data.distance} mi away{" "}
            </p>

            <p style={{ color: "#5976DD" }}>11:00 AM - 11:00 PM </p>
            <div className="gener_main">
              <span className="gener-name">
                {" "}
                {data?.MusicVibe3 ? data?.MusicVibe3 : "Jazz"}{" "}
              </span>{" "}
              &nbsp;
              <span className="gener-name">
                {data?.MusicVibe2 ? data?.MusicVibe2 : "Rock"}
              </span>
            </div>
            <div className="businness-info">
              <h3>Business info</h3>
              <p>Link to Menu</p>
              <button className="btn">
                {" "}
                <a
                  href={`https://${data.menulink}`}
                  target="_blank"
                  className="clicktovisit"
                >
                  {" "}
                  Click to visit menu link
                </a>
              </button>
              <p>Directions</p>
              <div className="maps">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyCLpRelH01xoapkwWD7w4chtFMQvjQPWn4",
                  }}
                  center={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  {/* <AnyReactComponent text={data.streetAddress} /> */}
                </GoogleMapReact>
              </div>
            </div>
            <ul className="yelpLink">
              <li>
                {data.streetAddress}{" "}
                <i
                  class="fa fa-map-marker"
                  aria-hidden="true"
                  style={{ color: "#79954E" }}
                />
              </li>
              <li>
                <a target="_blank" href={data.yelpURL}>Yelp Link</a>
                <i
                  class="fa fa-arrow-right"
                  aria-hidden="true"
                  style={{ color: "#79954E" }}
                />
              </li>

              <li>
                <span>
                  Call business <p>{data.phone}</p>
                </span>
                <a href="tel:657-332-4423">
                  {" "}
                  <i
                    class="fa fa-phone"
                    aria-hidden="true"
                    style={{ color: "#79954E" }}
                  />
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppleSingleresturant;
